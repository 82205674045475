"use client";
import { jsx as _jsx } from "react/jsx-runtime";
import { merchantState, rolesState } from "@/views/Home/state";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
export default function CanAccess(_a) {
    var allowed_level = _a.allowed_level, children = _a.children, _b = _a.show_text, show_text = _b === void 0 ? false : _b, _c = _a.fallback, fallback = _c === void 0 ? null : _c;
    var merchant = useRecoilValue(merchantState);
    var roles = useRecoilValue(rolesState);
    var _d = useState(), currentRole = _d[0], setCurrentRole = _d[1];
    useEffect(function () {
        if (!merchant)
            return;
        setCurrentRole(roles.find(function (r) { return r.id == merchant.role_id; }));
    }, []);
    if (!currentRole)
        return null;
    if (allowed_level.indexOf(currentRole === null || currentRole === void 0 ? void 0 : currentRole.id) === -1) {
        if (show_text)
            return (_jsx("p", { className: "p-8", children: "No access available. Please view different page." }));
        return fallback;
    }
    return children;
}
